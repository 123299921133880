const eventConstants = {
  home: {
    pageLoad: 'Page View: Home',
    pageName: 'Home',
    dataModalClicked: 'Modal: What We Do With Your Data',
    getStartedClicked: 'Connect an Account: Get Started',
    getStartedAction: {
      cleanInbox: 'Clean Up Your Inbox',
      unsubscribe: 'Block With One Click',
      rollup: 'Rollup your emails',
      organize: 'Organize your life'
    },
    cleanInbox: 'Clean Up Your Inbox',
    aboutCompany: 'About Our Company'
  },
  yourData: {
    pageLoad: 'Page View: Data Policy',
    pageName: 'Data Policy',
    riLinkClicked: 'External Link: Rakuten Intelligence Home'
  },
  app: {
    pageLoad: 'Page View: App Download',
    pageName: 'App Download',
    videoLinkClicked: 'Modal: Watch the Video Ad'
  },
  dataModal: {
    blogLinkClicked: 'External Link: Rakuten Intelligence Blog'
  },
  appRedirectModal: {
    redirectBtnClicked: 'Mobile Web App Download Reroute',
    btnTypeProp: 'Button Type'
  },
  privacy: {
    pageLoad: 'Page View: Privacy Policy',
    pageName: 'Privacy Policy'
  },
  terms: {
    pageLoad: 'Page View: Terms of Service',
    pageName: 'Terms of Service',
    adrRulesLinkClicked: 'External Link: Arbitration Rules'
  },
  common: {
    ctaProp: 'Call To Action',
    appTypeProp: 'App Type',
    visitedAppStore: 'Visited App Store',
    appType: {
      iOS: 'iOS',
      android: 'Android'
    },
    supportLinkClicked: 'Requested Support',
    supportType: {
      propName: 'Support Type',
      email: 'Email',
      zendesk: 'Zendesk'
    },
    supportCta: {
      propName: 'Support Call to Action',
      email: 'We are available by email at support@unroll.me',
      emailSupport: 'Email support@unroll.me',
      privacyEmail: 'Email privacy@unroll.me',
      zendesk: 'Service should be directed to our support team',
      contact: 'Contact Privacy Officer'
    }
  }
}

export default eventConstants
